
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CoverFlow from 'react-coverflow';
import { Player, BigPlayButton } from 'video-react';
import Footer from './components/Footer.js';

import { Image } from 'rebass';
import testimonials from './data/testimonials.json';

const TESTIMONIAL_DURATION = 6000;

export default function AboutPage() {
  const [baIndex, setBaIndex] = useState(0);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const testimonial = testimonials[testimonialIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setTestimonialIndex(i => (i+ 1) % testimonials.length);
    }, TESTIMONIAL_DURATION);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBaIndex(index => (index + 1) % 10);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='page'>
      <Helmet>
        <title>About - FitBoyZee</title>
      </Helmet>
      <section className='content'>
        <div className='block full'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
            <span>Before &amp; After</span>
              <span />
            </h2>
          </div>
          <CoverFlow
            autoplay
            height='1em'
            displayQuantityOfSide={2}
            navigation={false}
            enableHeading={false}
            infiniteScroll
            enableScroll={false}
            clickable={false}
            active={baIndex}
            media={{
              '@media (max-width: 900px)': {
                padding: '1em'
              },
              '@media (min-width: 900px)': {
                padding: '1em'
              }
            }}
            >
            <Image src='/images/beforeafter/beforeafter1.jpg' />
            <Image src='/images/beforeafter/beforeafter2.png' />
            <Image src='/images/beforeafter/beforeafter3.jpg' />
            <Image src='/images/beforeafter/beforeafter4.png' />
            <Image src='/images/beforeafter/beforeafter5.jpg' />
            <Image src='/images/beforeafter/beforeafter6.jpg' />
            <Image src='/images/beforeafter/beforeafter7.jpg' />
            <Image src='/images/beforeafter/beforeafter8.jpg' />
            <Image src='/images/beforeafter/beforeafter9.jpg' />
            <Image src='/images/beforeafter/beforeafter10.png' />
          </CoverFlow>
        </div>
        <div className='block block-testimonial'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
              <span>Testimonials</span>
              <span />
            </h2>
          </div>
          <div className='testimonial'>
            <img className='testimonial-image' alt={`${testimonial.name}`} src={testimonial.imageUrl} />
            <h4 className='testimonial-name'>{ testimonial.name }</h4>
            <p className='testimonial-message'>{ testimonial.message }</p>
          </div>
        </div>
        <div className='block'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
            <span>Video Interviews</span>
              <span />
            </h2>
          </div>
          <Player fluid loop  src='/images/video-interviews-coming-soon.mp4'>
            <BigPlayButton position="center" />
          </Player>
        </div>
      </section>
      <Footer />
    </div>
  );
}