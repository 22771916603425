
import styled from 'styled-components';

const SmolBreak = styled.br`
@media (min-width: 600px) {
  display: none;

  ::before {
    content: ' ';
  }
}
`;

export default SmolBreak;