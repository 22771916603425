
import React from 'react';
import { Helmet } from 'react-helmet';
import { Player, BigPlayButton } from 'video-react';
import Link from 'react-router-dom/Link';

import Footer from './components/Footer';

export default function HomePage() {

  return (
    <div className='page'>
      <Helmet>
        <title>Home - FitBoyZee</title>
      </Helmet>
      <section className='content'>
        <div className='hero'>
          <img 
            className='hero' 
            src='/images/banner.jpg' 
            alt='FitBoyZee: Get In Where You Fit In' 
            style={{ width: '100%' }} 
            />
        </div>
        <div className='block'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
              <span>Wholistic Wellness!</span>
              <span />
            </h2>
            <h4>&quot;Connecting Fitness to Form the Best You&quot;</h4>
          </div>
          <p>We are aware of the intersections between lifestyle, diet, fitness &amp; wellness. In order for you to live the life that you desire, your changes need to be wholistic and sustainable.</p>
          <p>Crash dieting may yield immediate results, but eventually, you need to return to your status quo. We provide you with mindful modifications that you can make and keep to provide you with permanent results!</p>
          <p>We will take a look at what you need and customize a program just for you!</p>
        </div>
        <div className='block'>
          <div className='text-center'>
            <h2 style={{ position: 'relative' }} className='accent accent-medium text-center'>
              <span>Meet Your Coach</span>
              <span />
            </h2>
            <img 
              src='/images/shirtless-osazee.jpg' 
              alt='Osazee Shirtless'
              style={{ width: '100%', position: 'relative', top: '-5em', zIndex: '-1100' }} 
              />
            <Player fluid src='/images/welcome-to-fitboyzee.mp4'>
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
        <div className='block text-center'>
          <Link className='btn' to='/apply'>
            Schedule a Phone Consultation
          </Link>
        </div>
        <div className='block text-center'>
          <img 
            src='/images/satisfaction.png' 
            alt='Satisfaction Guaranteed'
            style={{ height: '10em', width: '10em' }} 
            />
        </div>
      </section>
      <Footer />
    </div>
  );
}