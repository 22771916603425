
import React from 'react';
import { AnimatedSwitch, spring } from 'react-router-transition';

const STYLES = `
  .switch-wrapper {
    position: relative;
    height: 100%;
  }

  .switch-wrapper > div {
    position: absolute;
    min-height: 100%;
    width: 100%;
  }
`;

function mapStyles(styles) {
  return {
    display: 'flex',
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
    flex: 1
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

export default function ({ children }) {
  return(
    <>
      <style dangerouslySetInnerHTML={{ __html: STYLES }} />
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        mapStyles={mapStyles}
        className="switch-wrapper"
        >
        { children }
      </AnimatedSwitch>
    </>
  );
}