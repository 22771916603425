
import React from 'react';

import ResponsiveMenu from './components/ResponsiveMenu.js';

const items = [
  {
    name: 'Home',
    to: '/',
    exact: true
  },
  {
    name: 'About',
    to: '/about'
  },
  {
    name: 'Testimonials',
    to: '/testimonials'
  }
];

const itemsRight = [
  {
    name: 'Contact',
    to: '/contact'
  },
  {
    name: 'Apply',
    to: '/apply'
  },
  {
    name: 'Members',
    to: '/members'
  }
];

export default function MainNavigation() {

  return (
    <ResponsiveMenu 
      items={items} 
      itemsRight={itemsRight} 
      logoSrc='/images/fitboyzee-200x.png'
      header={
        <h4 style={{ margin: 0 }}>Fit Boy Zee</h4>
      } 
      />
  );
}