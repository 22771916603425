
import React from 'react';
import { BrowserRouter as Router , Route} from 'react-router-dom';
import Sticky from 'react-stickynode';

import './App.css';
import 'animate.css/animate.min.css';
import 'video-react/dist/video-react.css';

import AnimatedSwitch from './app/components/MyAnimatedSwitch.js';
import MainNavigation from './app/MainNavigation.js';

import HomePage from './app/HomePage.js';
import AboutPage from './app/AboutPage.js';
import ApplicationPage from './app/ApplicationPage.js';
import MemberPage from './app/MemberPage.js';
import TestimonialsPage from './app/TestimonialsPage.js';
import ContactPage from './app/ContactPage.js';

function App() {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Router>
        <Sticky innerZ={1000} enabled>
          <MainNavigation />
        </Sticky>
        <div style={{ flexGrow: 1, display: 'contents' }}>
          <AnimatedSwitch>
            <Route path={['/', '/home']} exact component={HomePage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/testimonials" exact component={TestimonialsPage} />
            <Route path="/apply" exact component={ApplicationPage} />
            <Route path="/members" exact component={MemberPage} />
            <Route path="/contact" exact component={ContactPage} />
          </AnimatedSwitch>
        </div>
      </Router>
    </div>
  );
}

export default App;
