
import React, { useMemo } from 'react';
import { FacebookIconLink, InstagramIconLink } from './icons.js';
import SmolBreak from './Layout/SmolBreak.js';

export default function Footer() {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer style={styles.container}>
      <p style={{ margin: 0 }}>
        Copyright &copy; {year} FitBoyZee.<SmolBreak /> All Rights Reserved.
      </p>
      <div style={{margin: '0.25rem'}}>
        <FacebookIconLink />
        <InstagramIconLink />
      </div>
    </footer>
  );
}

const styles = {
  container: {
    backgroundColor: '#88D3E1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center', 
    padding: '.5em',
    width: '100%'
  }
};