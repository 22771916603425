
import React from 'react';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/icomoon/facebook';
import { instagram } from 'react-icons-kit/icomoon/instagram';

export const FacebookIcon = (props) => (
  <Icon {...props} icon={facebook} />
);

export const InstagramIcon = (props) => (
  <Icon {...props} icon={instagram} />
);

export const FacebookIconLink = () => (
  <a href='https://facebook.com/pg/fitboyzee' target='_blank' rel='noopener noreferrer'>
    <FacebookIcon />
  </a>
);

export const InstagramIconLink = () => (
  <a href='https://instagram.com/fitboyzee' target='_blank' rel='noopener noreferrer'>
    <InstagramIcon />
  </a>
);