
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer.js';

export default function MemberPage() {

  return (
    <div className='page'>
      <Helmet>
        <title>Member Portal - FitBoyZee</title>
      </Helmet>
      <iframe 
        style={{flex: 1}} 
        title='Member Portal' 
        src='/ptdistinction.html' 
        width='100%' 
        frameBorder={0} 
        />
      <Footer />
    </div>
  );
}