
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer.js';

export default function AboutPage() {

  return (
    <div className='page'>
      <Helmet>
        <title>About - FitBoyZee</title>
      </Helmet>
      <section className='content'>
        <div className='block'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
            <span>About Osazee</span>
              <span />
            </h2>
          </div>
          <p>
            Osazee Edebiri founded FitBoyZee because of a passion to deliver our clients an experience unlike other online coaching programs.
          </p>
          <p>
            At Fitboyzee we train the body, mind &amp; spirit:
          </p>
          <p>
            <ul>
              <li><strong>Body</strong> through strategically assigned physical exercise.</li>
              <li><strong>Mind</strong> developing client&apos;s nutritional knowledge and presenting them with not just a list of exercises, but the wherewithal to understand how the movement is benefiting them.</li>
              <li>Most importantly, <strong>Spirit</strong>. Delivering our clients, the inspiration to achieve their goals, mentoring, and a wave of consistent positive vibes.</li>
            </ul>
          </p>
          <p>
            If you are not used to a workout or diet regiment, one of the biggest issues when building proper habits is boredom. You will be shifting from perhaps no plan to something more rigid. Fortunately, there are unlimited combinations that can be made when approached with creativity and that is just what we do! Our custom plan for you will still provide you variety in activity & diet. We are constantly researching new compatible paradigms to include in your program. We’ll keep it fun!
          </p>
          <p>
            At Fitboyzee we love healthy sustainable lifestyles, therefore we love to help everybody get there.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}