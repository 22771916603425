
import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer';

const defaultFormState = {
  name: '', 
  email: '', 
  message: ''
};

export default function ContactPage() {
  const [state, setState] = useState(defaultFormState);

  const update = useCallback(field => {
    return (e) => {
      const { value } = e.currentTarget;
      setState(currentState => ({
        ...currentState,
        [field]: value
      }));
    }
  }, []);

  const clear = useCallback(() => {
    setState(defaultFormState);
  }, []);

  return (
    <div className='page'>
      <Helmet>
        <title>Contact - FitBoyZee</title>
      </Helmet>
      <section className='content'>
        <div className='block'>
          <div className='text-center'>
            <h2 className='accent accent-medium'>
            <span>Contact Us</span>
              <span />
            </h2>
          </div>
          <form style={{ marginTop: '2em' }} onSubmit={e => e.preventDefault()}>
            <div className='form-group'>
              <label for='name'>Name</label>
              <input
                id='name'
                name='name'
                className='form-input'
                value={state.name}
                onChange={update('name')}
                placeholder='Your Name'
                required
                />
            </div>
            <div className='form-group'>
              <label for='email'>Email</label>
              <input
                id='email'
                name='email'
                className='form-input'
                type='email'
                value={state.email}
                onChange={update('email')}
                placeholder='email@example.com'
                required
                />
            </div>
            <div className='form-group'>
              <label for='message'>Message</label>
              <textarea
                id='message'
                name='message'
                className='form-input'
                value={state.message}
                onChange={update('message')}
                placeholder='Enter your message here...'
                lines={5}
                minHeight={150}
                required
                />
            </div>
            <button className='btn' style={{ float: 'right', margin: 0 }} onClick={clear}>
              Send
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}