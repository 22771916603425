
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer.js';

export default function ApplicationPage() {

  return (
    <div className='page'>
      <Helmet>
        <title>Application - FitBoyZee</title>
      </Helmet>
      <iframe 
        style={{flex: 1}} 
        title='Scheduler' 
        src='/scheduleonce.html'
        width='100%' 
        frameBorder={0} 
        />
      <Footer />
    </div>
  );
}