
import React, { useState, useCallback, useEffect } from 'react';
import NavLink from 'react-router-dom/NavLink';
import styled from 'styled-components';
import posed from 'react-pose';
import HamburgerMenu from 'react-hamburger-menu';
import { Image, Box } from 'rebass';
import { useLocation } from'react-router-dom';
import { FacebookIconLink, InstagramIconLink } from './icons.js';

const Mobile = styled.div`
  display: none;
  padding: 1em;
  text-align: center;
  border-bottom: 2px solid #88D3E1;
  overflow: hidden;
  @media (max-width: 720px) {
    display: block;
  }
`;

const FullScreenMenu = posed(Mobile)({
  open: {
    backgroundColor: '#88D3E1',
    borderBottomColor: '#FFF'
  },
  closed: { 
    backgroundColor: '#FFF',
    borderBottomColor: '#88D3E1'
  }
});

const MobileMenuItems = posed.div({
  open: {
    height: 'auto',
    overflow: 'hidden',
    padding: '1em',
    delayChildren: 200,
    staggerChildren: 50
  },
  closed: {
    height: 0,
    overflow: 'hidden',
    padding: '0em'
  }
});

const MobileMenuItem = posed.div({
  open: {
    opacity: 1
  },
  closed: {
    opacity: 0
  }
});

const ResponsiveMenu = ({ items = [], itemsRight, header, logoSrc }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation()
  const onToggleMenu = useCallback(() => {
    setShowMenu(showing => !showing);
  }, []);

  useEffect(() => {
      setShowMenu(() => false);
    }, 
    [location]
  );

  return (
    <div>
      <header className='desktop'>
        { items.map(({name, to, ...props}, i) => (
          <NavLink to={to} key={i} {...props}>
            { name }
          </NavLink>
        ))}
          
        { 
          logoSrc ? (
            <div style={{width: '8em', textAlign: 'left'}}>
              <Image
                src={logoSrc}
                sx={{
                  zIndex: 1000,
                  position: 'absolute',
                  top: 0,
                  height: '8em'
                }} />
            </div>
          ) : 
          header 
        }

        { Boolean(itemsRight) && itemsRight.map(({name, to, ...props}, i) => (
          <NavLink to={to} key={i} {...props}>
            { name }
          </NavLink>
        ))}

        <div className='navigation-social'>
          <FacebookIconLink />
          <InstagramIconLink />
        </div>
        
      </header>
      <FullScreenMenu pose={showMenu ? 'open' : 'closed'}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <HamburgerMenu
            isOpen={showMenu}
            menuClicked={onToggleMenu}
            strokeWidth={3}
            width={30}
            height={24}
            color={showMenu ? 'white' : 'black'}
            />
          
          <div style={{ flex: 1, color: showMenu ? 'white' : 'black' }}>
            { header }
          </div>

          <Image
            src={logoSrc}
            sx={{
              height: '30px'
            }} />
        </div>
        <MobileMenuItems>
          { items.map(({name, to, ...props}, i) => (
            <MobileMenuItem key={i} style={{paddingTop: '.5em'}}>
              <NavLink to={to} {...props}>
                { name.toUpperCase() }
              </NavLink>
            </MobileMenuItem>
          ))}
          { Boolean(itemsRight) && itemsRight.map(({name, to, ...props}, i) => (
            <MobileMenuItem key={i} style={{paddingTop: '.5em'}}>
              <NavLink to={to} {...props}>
                { name.toUpperCase() }
              </NavLink>
            </MobileMenuItem>
          ))}
          <Box m={3}>
            <FacebookIconLink />
            <InstagramIconLink />
          </Box>
        </MobileMenuItems>
      </FullScreenMenu>
    </div>
  );
}

export default ResponsiveMenu;